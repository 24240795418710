.footer {
  color: white;
  height: 80px;
  z-index: 9999;
}

.footer-logo {
  background-image: url("../resources//widelogo-360p-white.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  height: 40px;
  margin-top: 20px;
  opacity: 1;
}

.legal {
  font-family: Round !important;
  font-size: 15px !important;
  line-height: 80px;
  text-align: center;
  text-overflow: clip !important;
  white-space: nowrap !important;
  margin-bottom: 0;
}
