h1 {
  font-size: 1.8em;
}

.element-container {
  float: left;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  user-select: none;
  transition: transform cubic-bezier(0.52, 1.95, 0.81, 0.37) 0.2s;
  transform: perspective(600px) rotate3d(0, 1, 0, 0deg);
  transform-origin: 80% 50%;
}

.element {
  position: relative;
  background-size: cover;
  overflow: hidden;
  box-shadow: 0px 3px 15px 6px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  height: 70px;
  transition: transform cubic-bezier(0.52, 1.95, 0.81, 0.37) 0.2s;
}

.element-background,
.element-overlay,
.element-overlay-shine {
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.element-background {
  z-index: 0;
  background-size: cover;
  background-position: center;
}
.element-overlay {
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 10%,
    rgba(0, 0, 0, 0.3) 60%
  );
  border-radius: 6px;
}

.element-overlay-new {
  user-select: none;
  font-family: Round;
  z-index: 3;
  position: absolute;
  text-align: center;
  font-size: 17px;
  vertical-align: top;
  width: 20%;
  line-height: 30px;
  border-radius: 10px 10px 10px 10px;
  top: -10px;
  left: 80%;
}

.element-overlay-shine {
  z-index: 2;
  background: #000000;
  background: -moz-linear-gradient(
    40deg,
    rgba(0, 0, 0, 0) 25%,
    #ffffff 50%,
    rgba(0, 0, 0, 0) 75% rgba(0, 0, 0, 0) 91%
  );
  background: -webkit-linear-gradient(
    40deg,
    rgba(0, 0, 0, 0) 25%,
    #ffffff 50%,
    rgba(0, 0, 0, 0) 75%
  );
  background: linear-gradient(
    40deg,
    rgba(0, 0, 0, 0) 25%,
    #ffffff 50%,
    rgba(0, 0, 0, 0) 75%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=1 );
  opacity: 0;
  transition: background-position ease 0.2s, opacity ease 0.2s;
}

.element-title,
.element-subtitle {
  font-family: Round;
  position: relative;
  z-index: 3;
  text-shadow: 0 5px 5px black;
  transition: text-shadow cubic-bezier(0.52, 1.95, 0.81, 0.37) 0.2s;
  margin-left: 15px !important;
  margin-top: 11px !important;
  white-space: nowrap !important;
}

.element-subtitle {
  margin-left: 15px;
  margin-top: -5px !important;
  font-size: 20px;
}

.element-container:hover {
  transform: perspective(800px) rotate3d(0, 1, 0.05, 15deg);
  cursor: pointer;
}

.element-container:hover .element-overlay-shine {
  background-position: 11vw;
  opacity: 0.3;
}
.element-container:hover .element-title,
.element-container:hover .element-subtitle {
  text-shadow: 3px 5px 5px black;
}

a:hover {
  text-decoration: none;
}
