.element-search-container {
  overflow-y: visible;
  transition: max-height ease-out 0.3s, padding-top ease-out 0.3s;
}

.category-row {
  padding-top: 0px;
  padding-bottom: 30px;
  margin-bottom: 10px;
}

.category-title-row {
  margin-bottom: 20px;
  margin-top: 10px;
  border-radius: 10px;

  background: #ffffff2a;
  background: linear-gradient(90deg, #ffffff2a, #ffffff00);
}

.category-title h1 {
  margin: 0;
  line-height: 60px;
  vertical-align: middle;
  font-family: Round !important;
  font-size: 40px !important;
  text-shadow: 0 5px 10px rgba(0, 0, 0, 0.777);
  padding-left: 10px;
}

.search-result-title {
  vertical-align: middle;
  text-align: center;
  line-height: 60px;
  font-family: Round !important;
  font-size: 40px !important;
  text-shadow: 0 5px 10px rgba(0, 0, 0, 0.777);
}
