.container {
  overflow-y: visible;
  transition: max-height ease-out 0s;
}

.map-container {
  margin: 30px 0 30px 0;
  user-select: none;
}

.map-wrapper {
  position: relative;
  width: 60%;
  margin-left: 40%;
  max-height: 90vh;
}

.map-image img {
  filter: drop-shadow(0 0 50px rgb(0, 0, 0, 0.4));
  user-select: none;
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 90vh;
}

.map-image,
.pins-wrapper {
  position: relative;
  width: 100%;
  user-select: none;
}

.social:hover > .social-text,
.social:hover > a > .social-text {
  opacity: 1 !important;
  max-width: 200px !important;
}

.social:hover > .social-image {
  opacity: 1 !important;
}

.social-container {
  white-space: nowrap;
}

.social {
  margin-left: 5px;
  margin-bottom: 10px;
  width: auto;
  height: 50px;
  display: inline-block;
  vertical-align: top;
}

.social-image {
  width: 50px;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  transition: opacity ease-in-out 0.2s;
  opacity: 0.5;
  display: inline-block;
  vertical-align: top;
}

.social-text {
  margin-left: 5px;
  line-height: 53px;
  font-size: 20px;
  transition: opacity ease-in-out 0.2s, max-width ease-in-out 0.2s;
  opacity: 0;
  width: auto;
  max-width: 0px;
  overflow: hidden;
  font-family: Round;
  color: rgba(255, 255, 255, 0.61);
  display: inline-block;
  font-weight: bold;
}

.social-image.instagram {
  background-image: url("../resources/instagram-logo.png");
}

.social-image.email {
  background-image: url("../resources/mail-logo.png");
}

.wide-logo {
  display: none;
  opacity: 1;
  margin-left: 0px;
  margin-bottom: -10px;
  width: auto;
  max-width: 300px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: bottom;
  background-image: url("../resources/widelogo-360p-white.png");
}

.pins-wrapper {
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}

.list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list-floater {
  padding-right: 25px;
}

.collection-select-box {
  user-select: none;
  font-family: Round;
  border-radius: 10px;
  height: 150px;
  line-height: 40px;
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
  transition: transform cubic-bezier(0.52, 1.95, 0.81, 0.37) 0.2s;
  transform: perspective(600px) rotate3d(0, 1, 0, 0deg);
  transform-origin: 80% 50%;
  max-width: 300px;
  white-space: nowrap !important;
  background-size: cover;
  background-position: center;
  background-image: url("../media/welcome.jpg");
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.438);
  z-index: 0;
}

.collection-select-element-name {
  float: left;
  width: 70%;
}
.collection-select-element-count {
  text-align: right;
  font-size: 20px;
  vertical-align: top;
  float: right;
  width: 30%;
}

.collection-select-element-new {
  position: absolute;
  text-align: center;
  font-size: 17px;
  vertical-align: top;
  width: 15%;
  line-height: 30px;
  border-radius: 10px 10px 10px 5px;
  top: -20px;
  left: 98%;
}

.collection-select-element-title {
  user-select: none;
  font-family: Round;
  height: 20px;
  margin-bottom: 10px;
  font-size: 12px;
  white-space: nowrap !important;
}

.collection-select-element {
  user-select: none;
  font-family: Round;
  border-radius: 10px;
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  margin-bottom: 5px;
  transition: transform cubic-bezier(0.52, 1.95, 0.81, 0.37) 0.2s;
  transform: perspective(600px) rotate3d(0, 1, 0, 0deg);
  transform-origin: 80% 50%;
  max-width: 300px;
  white-space: nowrap !important;
}

.collection-select-element h1 {
  width: 95%;
}

.collection-select-element:hover,
.list-hover {
  transform: perspective(800px) rotate3d(0, 1, 0.05, 15deg);
  cursor: pointer;
}
.collection-select-element h1 {
  line-height: 40px;
}

.collection-select {
  transition: transform cubic-bezier(0.52, 1.95, 0.81, 0.37) 0.2s;
  transform: scaleY(1.001);
  transform-origin: 50% 60%;
  position: absolute;
  z-index: 1;
}

.collection-select-container {
  position: absolute;
}

.select-circle {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background-image: url("../resources/pin.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  transform: translate(-15px, -30px);
}

.select-text {
  transition: opacity ease-in-out 0.2s;
  opacity: 0;
  top: 0;
  left: 0;
  position: absolute;
  width: 0;
  font-family: Round;
  transform: translate(15px, -30px);
  color: rgba(255, 255, 255, 0.61);
  z-index: 1;
}

.collection-select:hover > .select-text,
.pin-hover > .select-text {
  opacity: 1 !important;
}

.collection-select:hover,
.pin-hover {
  transform: scale(1.6);
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .list-container {
    min-height: 60vh;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    display: block !important;
    margin-top: 50px;
  }
  .container {
    /*background-image: url("/resources/sverige.svg");
    background-repeat: no-repeat;
    background-position-x: -60px;
    background-position-y: -650px;
    background-size: 160%;*/
    min-height: 80vh;
    min-width: 100vw;
  }
  .wide-logo {
    display: block;
  }
}
