/* Patterns */
.noise {
  background-image: url("./resources/noise.png");
  background-repeat: repeat;
  background-size: auto;
}

/* Colors */
.background-color-base {
  background-color: #242424;
}
.background-color-dark {
  background: #00000044;
  background: linear-gradient(230deg, #0000008c, #0f0f0f27);
  -webkit-animation: AnimationName 15s ease infinite;
  -moz-animation: AnimationName 15s ease infinite;
  animation: AnimationName 15s ease infinite;
}
.background-color-light {
  background: #ffffff0e;
  background: linear-gradient(230deg, #4e4e4e28, #ffffff46);
  -webkit-animation: AnimationName 15s ease infinite;
  -moz-animation: AnimationName 15s ease infinite;
  animation: AnimationName 15s ease infinite;
}
.background-color-positive {
  background: #629e3a;
  background: linear-gradient(230deg, #3b5f23, #629e3a);
  -webkit-animation: AnimationName 15s ease infinite;
  -moz-animation: AnimationName 15s ease infinite;
  animation: AnimationName 15s ease infinite;
}

.text-color-light {
  color: rgb(255, 255, 255) !important;
}
.text-color-base {
  color: rgb(206, 206, 206) !important;
}
.text-color-dark {
  color: rgb(143, 143, 143) !important;
}

.background-gradient-base {
  background: #0f0f0f;
  background: linear-gradient(230deg, #494949, #0f0f0f);
  background-size: 200% 200%;

  -webkit-animation: AnimationName 15s ease infinite;
  -moz-animation: AnimationName 15s ease infinite;
  animation: AnimationName 15s ease infinite;
}

/* Links */
a {
  color: rgba(81, 168, 255, 0.795) !important;
}

/* Scrollbar */
*::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #444444;
}

*::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(20, 20, 20);
}

/* Textbox */
[type="text"],
[type="text"]:focus {
  border-radius: 100px !important;
  border: 0 !important;
  background-color: rgb(90, 90, 90) !important;
  font-family: Round !important;
  color: rgb(219, 219, 219) !important;
}

::placeholder {
  font-family: Round !important;
  color: rgb(146, 146, 146) !important;
}

/* Toggle */
.toggle-control {
  display: block;
  position: relative;
  padding-left: 100px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}

.toggle-control input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.toggle-control input:checked ~ .control {
  background: dodgerblue;
  background: linear-gradient(90deg, dodgerblue 0%, dodgerblue 100%);
}

.toggle-control input:checked ~ .control:after {
  left: 55px;
}

.toggle-control .control {
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 100px;
  border-radius: 25px;
  background: darkgray;
  background: linear-gradient(90deg, darkgray 0%, darkgray 100%);
  transition: background-color 0.15s ease-in;
}

.toggle-control .control:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 40px;
  height: 40px;
  border-radius: 25px;
  background: white;
  transition: left 0.15s ease-in;
}

/* Fonts */
@font-face {
  font-family: "Round";
  src: url("./resources/fonts/proxima-nova-soft.otf");
}

/* Animation */
@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}
